const openCategories: string[] = []
let lastSelectedSong: number | null = null

const getOpenCategories = (): string[] => openCategories

const setOpenCategories = (newOpenCategories: string | string[]): void => {
  openCategories.length = 0

  if (typeof newOpenCategories === 'string') {
    openCategories.push(newOpenCategories)
  } else {
    openCategories.push(...newOpenCategories)
  }
}

const getLastSelectedSong = (): number | null => {
  return lastSelectedSong
}

const setLastSelectedSong = (songId: number): void => {
  lastSelectedSong = songId
}

const songListSelectionHelper = {
  getOpenCategories,
  setOpenCategories,
  getLastSelectedSong,
  setLastSelectedSong
}

export default songListSelectionHelper
