import React from 'react'
import { useHistory } from 'react-router-dom'
import { Category } from 'raspu-web-interface-models/Category'
import { SongListItem } from 'raspu-web-interface-models/SongListItem'
import SongListSearch from './SongListSearch'
import SongListCategoryView from './SongListCategoryView'
import SongListSearchView from './SongListSearchView'
import LoadingIcon from './LoadingIcon'
import SearchIcon from './SearchIcon'
import songListSelectionHelper from '../utils/songListSelectionHelper'
import scrollToSong from '../utils/scrollToSong'
import { SongSearchHandler } from '../interfaces/SongSearchHandler'

interface SongListProps extends SongSearchHandler {
  readonly categories: Category[]
  readonly songList: SongListItem[]
}

const SongList: React.FC<SongListProps> = ({ categories, songList, songSearch, setSongSearch }) => {
  const [searchEmpty, setSearchEmpty] = React.useState<boolean>(songSearch === null)
  const [searching, setSearching] = React.useState<boolean>(false)
  const history = useHistory()

  // Scroll to last selected song
  React.useEffect(() => {
    const lastSelected = songListSelectionHelper.getLastSelectedSong()
    if (lastSelected) {
      scrollToSong(lastSelected)
    }
  }, [])

  const handleSongClick = (songId: number) => {
    songListSelectionHelper.setLastSelectedSong(songId)
    history.push(`/${songId}`)
  }

  const resetSongSearch = () => {
    setSongSearch(null)
  }

  return (
    <div>
      <SongListSearch
        songSearch={songSearch}
        setSongSearch={setSongSearch}
        setSearchEmpty={empty => {
          setSearchEmpty(empty)
          if (empty) {
            resetSongSearch()
          }
        }}
        setSearching={setSearching}
        searchChanged={resetSongSearch}
        filterUnexistingSong={match => match.filter(s => songList.find(ss => ss.id === s.id))}
      />

      {searching && <LoadingIcon />}
      {!searchEmpty && !searching && !songSearch && <SearchIcon />}
      {
        !searchEmpty && !searching && songSearch &&
        <SongListSearchView
          searchResult={songSearch} 
          handleClick={handleSongClick}
        />
      }
      {
        searchEmpty &&
        <SongListCategoryView
          categories={categories}
          songList={songList}
          handleClick={handleSongClick}
        />
      }
    </div>
  )
}

export default SongList
