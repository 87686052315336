import React from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Input } from 'antd'
import {
  HomeOutlined,
  DoubleRightOutlined,
  ArrowLeftOutlined,
  ArrowRightOutlined
} from '@ant-design/icons'

interface NavigationBarProps {
  readonly loading: boolean
  readonly isHomePage: boolean
  readonly previousSongId: number | null
  readonly nextSongId: number | null
}

const SONG_NUMBER_INPUT_ID = 'songNumberInput'

const NavigationBar: React.FC<NavigationBarProps> = ({
  loading,
  isHomePage,
  previousSongId,
  nextSongId,
}) => {
  const [inputValue, setInputValue] = React.useState<string>('')
  const history = useHistory()
  const enabled = inputValue !== ''

  const handleSearch = () => {
    if (inputValue) {
      const input = document.getElementById(SONG_NUMBER_INPUT_ID)
      if (input) {
        input.blur()
      }
      history.push(`/${inputValue}`)
      setInputValue('')
    }
  }

  const handlePreviousArrowPress = () => {
    if (previousSongId) {
      history.push(`/${previousSongId}`)
    } else {
      handleHomePress()
    }
  }

  const handleNextArrowPress = () => {
    if (nextSongId) {
      history.push(`/${nextSongId}`)
    }
  }

  const handleHomePress = () => {
    history.push('')
  }

  const previousArrowEnabled = (): boolean => !loading
    ? previousSongId !== null
    : loading

  const nextArrowEnabled = (): boolean => !loading
    ? nextSongId !== null
    : loading

  const getMoveIcon = () => (
    <DoubleRightOutlined
      onClick={handleSearch}
      style={{
        fontSize: 20,
        opacity: enabled ? 1 : 0.5, transition: 'opacity 0.2s ease-out'
      }}
    />
  )

  return (
    <div className='navigation-bar'>
      <Button
        size='large'
        icon={<ArrowLeftOutlined />}
        disabled={!previousArrowEnabled()}
        onClick={handlePreviousArrowPress}
      />

      <Button
        size='large'
        icon={<HomeOutlined />}
        type={isHomePage ? 'primary' : 'default'}
        onClick={handleHomePress}
      />

      <Input
        id={SONG_NUMBER_INPUT_ID}
        type='number'
        inputMode='numeric'
        size='large'
        placeholder='Siirry'
        value={inputValue}
        onChange={e => setInputValue(e.target.value)}
        onPressEnter={handleSearch}
        suffix={getMoveIcon()}
        style={{ width: '40%' }}
      />

      <Button
        size='large'
        icon={<ArrowRightOutlined />}
        disabled={!nextArrowEnabled()}
        onClick={handleNextArrowPress}
      />
    </div>
  )
}

export default NavigationBar
