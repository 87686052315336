import React from 'react'
import SongNotFound from './SongNotFound'
import LoadingIcon from './LoadingIcon'
import songService from '../services/song'
import songRepository from '../utils/songRepository'
import { Song } from '../../../interface-models/Song'

interface SongPageProps {
  readonly songId: number | null
  readonly songCategory: string | null
  readonly songFound: boolean
}

const SongPage: React.FC<SongPageProps> = ({ songId, songCategory, songFound }) => {
  const [song, setSong] = React.useState<Song | null>(null)
  const [loadingSong, setLoadingSong] = React.useState<boolean>(true)

  React.useEffect(() => {
    const loadSongData = async () => {
      if (songId) {
        const song = songRepository.getSong(songId)
        if (song) {
          setSong(song)
          setLoadingSong(false)
          return
        }
      }

      try {
        // Only try to load song data if song found
        if (songFound) {
          const songData = await songService.getSong(songId!)
          songRepository.addSong(songData)
          setSong(songData)
        } else {
          setSong(null)
        }
      } catch (error) {
        // No need to handle error
      }

      setLoadingSong(false)
      window.scrollTo(0, 0);
    }

    // Set loading if not already loading
    if (!loadingSong) {
      setLoadingSong(true)
    }

    loadSongData()
  }, [songId]) // eslint-disable-line

  if (loadingSong) {
    return <LoadingIcon />
  } else if (!loadingSong && !song) {
    return <SongNotFound />
  }

  return (
    <div className='song-wrapper'>
      <div className='song-category'>
        {songCategory}
      </div>

      <div className='song-title'>
        <b>{song?.id}</b> {song?.name}
      </div>

      <div className='song-tune'>
        {song?.tune}
      </div>

      <div className='song-lyrics'>
        {song?.lyrics}
      </div>
    </div>
  )
}

export default SongPage
