import React from 'react'
import { Carousel } from 'antd'
import imageService from '../services/image'
import { ImageListItem } from '../../../interface-models/ImageListItem'

const BottomCarousel: React.FC = () => {
  const [images, setImages] = React.useState<ImageListItem[]>([])

  React.useEffect(() => {
    const loadImages = async () => {
      try {
        const imageData = await imageService.getImages()
        setImages(imageData)
      } catch (error) {
      }
    }

    loadImages()
  }, []) // eslint-disable-line

  if (images.length === 0) {
    return null
  }

  return (
    <Carousel
      className='bottom-carousel'
      autoplay
      effect='fade'
      dotPosition='top'
      autoplaySpeed={6000}
    >
      {
        images.map(image =>
          <a key={image.alt} href={image.link} target="_blank" rel='noreferrer'>
            <img
              className='bottom-carousel-content'
              src={imageService.buildImageUrl(image.name)}
              alt={image.alt}
            />
          </a>
        )
      }
    </Carousel>
  )
}
export default BottomCarousel
