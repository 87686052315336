import React from 'react'
import { Spin } from 'antd'

const LoadingIcon: React.FC = () => (
  <div className='icon'>
    <Spin size='large' />
  </div>
)

export default LoadingIcon
