import React from 'react'
import { Input } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import { SongListItem } from 'raspu-web-interface-models/SongListItem'
import songService from '../services/song'
import { SongSearchHandler } from '../interfaces/SongSearchHandler'

interface SongListSearchProps extends SongSearchHandler {
  readonly setSearchEmpty: (empty: boolean) => void
  readonly setSearching: (searching: boolean) => void
  readonly searchChanged: () => void
  readonly filterUnexistingSong: (match: SongListItem[]) => SongListItem[]
}

const INPUT_ID = "SongListSearch"
const SEARCH_WAIT_MS = 1000
let currentTimeout: NodeJS.Timeout | null = null

const SongListSearch: React.FC<SongListSearchProps> = ({
  songSearch,
  setSongSearch,
  setSearchEmpty,
  setSearching,
  searchChanged: setSearchChanging,
  filterUnexistingSong
}) => {
  const [searchValue, setSearchValue] = React.useState<string>(songSearch ? songSearch.pattern : '')

  const handleSearchPatternChange = (newSearchPattern: string) => {
    setSearchValue(newSearchPattern)
    setSearchChanging()
    const empty = newSearchPattern.length === 0

    if (currentTimeout !== null) {
      clearTimeout(currentTimeout)
    }

    setSearchEmpty(empty)
    if (empty) {
      return
    }

    currentTimeout = setTimeout(async () => {
      setSearching(true)
      const match = filterUnexistingSong(await songService.searchSong(newSearchPattern))
      setSongSearch({ pattern: newSearchPattern, match })
      setSearching(false)
      currentTimeout = null
    }, SEARCH_WAIT_MS)
  }

  const handleEnter = () => {
    const input = document.getElementById(INPUT_ID)
    if (input) {
      input.blur()
    }
  }

  return (
    <Input
      id={INPUT_ID}
      size='large'
      value={searchValue}
      onChange={e => handleSearchPatternChange(e.target.value)}
      prefix={<SearchOutlined style={{ fontSize: 22, marginRight: 5 }} />}
      placeholder='Hae numerolla, nimellä, sanoilla...'
      allowClear
      onPressEnter={handleEnter}
      style={{ marginTop: 5 }}
    />
  )
}

export default SongListSearch
