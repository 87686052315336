import React from 'react'

const RasputinTitle: React.FC = () => (
  <div>
    <p className='rasputin-title'>
      Rasputin
    </p>
  </div>
)

export default RasputinTitle
