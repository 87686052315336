import { Song } from 'raspu-web-interface-models/Song'

// Keep 100 songs in cache
const MAX_AMOUNT = 100
const songs = new Map<number, Song>()
const lastAdded: number[] = []

const getSong = (id: number): Song | null => {
  const song = songs.get(id)
  return song ? song : null
}

const addSong = (song: Song): void => {
  if (lastAdded.length >= MAX_AMOUNT) {
    const songIdToRemove = lastAdded.shift()
    songs.delete(songIdToRemove!)
  }

  songs.set(song.id, song)
  lastAdded.push(song.id)
}

const songRepository = {
  getSong,
  addSong
}

export default songRepository
