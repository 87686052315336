import React from 'react'
import { List, Typography } from 'antd'
import SongListRow from './SongListRow'
import { SongClickHandler } from '../interfaces/SongClickHandler'
import { SongSearchResult } from '../interfaces/SongSearchResult'

interface SongListSearchViewProps extends SongClickHandler {
  readonly searchResult: SongSearchResult
}

const SongListSearchView: React.FC<SongListSearchViewProps> = ({ searchResult, handleClick }) => (
  <div style={{ marginTop: 10 }}>
    <Typography.Title level={4} style={{ textAlign: 'center' }}>
      {
        searchResult.match.length === 0
          ? <span>Ei hakuosumia :(</span>
          : <span>{searchResult.match.length} {`hakuosuma${searchResult.match.length > 1 ? 'a' : ''}`}:</span>
      }
    </Typography.Title>
    <List size='small'>
      {searchResult.match.map(song =>
        <SongListRow
          key={song.id}
          number={song.id}
          name={song.name}
          handleClick={handleClick}
        />
      )}
    </List>
  </div>
)

export default SongListSearchView
