import createSongLisRowId from "./createSongListRowId"

const scrollToSong = (songId: number): void => {
  const songElement = document.getElementById(createSongLisRowId(songId))

  if (songElement) {
    songElement.scrollIntoView({ block: 'center', inline: 'center' })
  }
}

export default scrollToSong
