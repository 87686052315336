import React from 'react'
import { SearchOutlined } from '@ant-design/icons'

const SearchIcon: React.FC = () => (
  <div className='icon'>
    <SearchOutlined className='bounce' style={{ fontSize: 64 }} />
  </div>
)

export default SearchIcon
