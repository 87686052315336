import React from 'react'
import { List } from 'antd'
import createSongLisRowId from '../utils/createSongListRowId'
import songListSelectionHelper from '../utils/songListSelectionHelper'

interface SongListRowProps {
  readonly number: number
  readonly name: string
  readonly handleClick: (songId: number) => void
}

const SongListRow: React.FC<SongListRowProps> = ({ number, name, handleClick }) => (
  <List.Item
    id={createSongLisRowId(number)}
    className='songlist-row'
    onClick={() => {
      songListSelectionHelper.setLastSelectedSong(number)
      handleClick(number)
    }}
  >
    <b>{number}</b> {name}
  </List.Item>
)

export default SongListRow
