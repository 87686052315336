import axios from 'axios'
import { Category } from 'raspu-web-interface-models/Category'

const baseUrl = '/api/category'

const getCategories = async (): Promise<Category[]> => {
  const response = await axios.get(baseUrl)
  return response.data
}

const categoryService = {
  getCategories
}

export default categoryService
