import React from 'react'
import { FrownOutlined } from '@ant-design/icons'


const SongNotFound: React.FC = () => (
  <div style={{ textAlign: 'center' }}>
    Etsimääsi laulua:
    <br/>
    <b>{window.location.pathname.substring(1)}</b>
    <br/>
    ei löytynyt :(
    <br/>
    <FrownOutlined style={{ fontSize: '5rem', marginTop: '5%' }} />
  </div>
)

export default SongNotFound
