import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import RasputinTitle from './components/RasputinTitle'
import NavigationBar from './components/NavigationBar'
import SongList from './components/SongList'
import SongPage from './components/SongPage'
import LoadingIcon from './components/LoadingIcon'
import BottomCarousel from './components/BottomCarousel'
import categoryService from './services/category'
import songService from './services/song'
import { Category } from '../../interface-models/Category'
import { SongListItem } from '../../interface-models/SongListItem'
import './antd.css'
import './App.css'
import { SongSearchResult } from './interfaces/SongSearchResult'

interface SongMatchParams {
  readonly songId: string
}

interface PreviousAndNextSongId {
  readonly currentSong: SongListItem | null
  readonly previousSongId: number | null
  readonly nextSongId: number | null
}

const TITLE = 'Rasputin'

const App: React.FC = () => {
  const [loading, setLoading] = React.useState<boolean>(true)
  const [categories, setCategories] = React.useState<Category[]>([])
  const [songList, setSongList] = React.useState<SongListItem[]>([]) // Songs are always ordered!
  const [songSearch, setSongSearch] = React.useState<SongSearchResult | null>(null)

  // Load categories and songs from the server
  React.useEffect(() => {
    const loadDataFromServer = async () => {
      setCategories(await categoryService.getCategories())
      setSongList(await songService.getSongList())
      setLoading(false)
    }

    loadDataFromServer()
  }, [])

  const setAppHeight = () => document.documentElement.style.setProperty('--app-height', `${window.innerHeight}px`)
  window.addEventListener('resize', setAppHeight)
  setAppHeight()

  const songIdToNumber = (songIdFromRoute: string): number | null => {
    const songIdNum = Number(songIdFromRoute)
    if (!Number.isNaN(songIdNum) && songIdNum.toString() === songIdFromRoute) {
      return songIdNum
    }

    return null
  }

  const findPreviousAndNextSongIds = (songId: number | null): PreviousAndNextSongId => {
    // Song ID not null
    if (songId) {
      const currentSongIndex = songList.findIndex(song => song.id === songId)
      if (currentSongIndex !== -1) {
        // Song found with the given index
        const previousSong = songList[currentSongIndex - 1]
        const nextSong = songList[currentSongIndex + 1]
        return {
          currentSong: songList[currentSongIndex],
          previousSongId: previousSong ? previousSong.id : null,
          nextSongId: nextSong ? nextSong.id : null
        }
      }
    }

    // Song ID not found
    return {
      currentSong: null,
      previousSongId: null,
      nextSongId: null
    }
  }

  const getContentComponent = (isSongMatch: boolean) => {
    if (loading) {
      return <LoadingIcon />
    }

    if (isSongMatch) {
      return (
        <SongPage
          songId={songId}
          songCategory={songCategory}
          songFound={previousAndNextSongIds.currentSong !== null}
        />
      )
    } else {
      return (
        <SongList
          categories={categories}
          songList={songList}
          songSearch={songSearch}
          setSongSearch={setSongSearch}
        />
      )
    }
  }

  // Check for song ID
  const songMatch = useRouteMatch<SongMatchParams>('/:songId')
  const songId = songMatch ? songIdToNumber(songMatch.params.songId) : null
  const previousAndNextSongIds: PreviousAndNextSongId = findPreviousAndNextSongIds(songId)

  // Check song category
  const category = previousAndNextSongIds.currentSong
    ? categories.find(category => category.id === previousAndNextSongIds.currentSong?.category)
    : null
  const songCategory = category ? category.name : null

  // Set document title
  document.title = previousAndNextSongIds.currentSong
    ? `${TITLE}: ${previousAndNextSongIds.currentSong.id} ${previousAndNextSongIds.currentSong.name}`
    : TITLE

  return (
    <div className='background' style={{ backgroundImage: 'url(/bg.png)' }}>
      <div className='container-wrapper'>
        <RasputinTitle />

        <NavigationBar
          loading={loading}
          isHomePage={songMatch === null}
          previousSongId={previousAndNextSongIds.previousSongId}
          nextSongId={previousAndNextSongIds.nextSongId}
        />

        <div className='paper'>
          <div className='paper-content'>
            {getContentComponent(songMatch !== null)}
          </div>
        </div>

        <BottomCarousel />
      </div>
    </div>
  )
}

export default App
