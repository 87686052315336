import axios from 'axios'
import { ImageListItem } from 'raspu-web-interface-models/ImageListItem'

const baseUrl = '/api/image'

const getImages = async (): Promise<ImageListItem[]> => {
  const response = await axios.get(baseUrl)
  return response.data
}

const buildImageUrl = (name: string): string => `${baseUrl}/${name}`

const imageService = {
  getImages,
  buildImageUrl
}

export default imageService
