import axios from 'axios'
import { Song } from 'raspu-web-interface-models/Song'
import { SongListItem } from 'raspu-web-interface-models/SongListItem'

const baseUrl = '/api/song'

const getSongList = async (): Promise<SongListItem[]> => {
  const response = await axios.get(baseUrl)
  return response.data
}

const getSong =  async (id: number): Promise<Song> => {
  const url = `${baseUrl}/${id}`
  const response = await axios.get(url)
  return response.data
}

const searchSong = async (pattern: string): Promise<SongListItem[]> => {
  try {
    const response = await axios.get(`${baseUrl}?any=${pattern}`)
    return response.data
  } catch {
    return [] // Return empty with invalid search parameters
  }
}

const songService = {
  getSongList,
  getSong,
  searchSong
}

export default songService
